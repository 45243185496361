import { j as i } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as l } from "./chunks/clsx.0d472180.js";
import { Heading as o } from "./heading.mjs";
import "./text.mjs";
import { u as n } from "./chunks/OverlayContext.c337c144.js";
import { c as s } from "./chunks/styles.b9d17517.js";
import "react";
import "./chunks/cn.2ea4f521.js";
import "./chunks/TypographyElement.80f8cab9.js";
const a = ({ className: e, ...t }) => {
  const r = n();
  return /* @__PURE__ */ i.jsx(o, { as: "h2", variant: "s", id: r, className: l(s.root, e), ...t });
};
try {
  a.displayName = "ModalTitle", a.__docgenInfo = { description: "", displayName: "ModalTitle", props: { inline: { defaultValue: null, description: "", name: "inline", required: !1, type: { name: "boolean" } }, align: { defaultValue: null, description: "", name: "align", required: !1, type: { name: '"center" | "right" | "left" | "justify"' } }, textColor: { defaultValue: null, description: "", name: "textColor", required: !1, type: { name: '"dark" | "light" | "darkSecondary" | "highlighted" | "inherited"' } }, variant: { defaultValue: null, description: "", name: "variant", required: !1, type: { name: "HeadingVariant" } } } };
} catch {
}
export {
  a as ModalTitle
};
